import { defineStore } from 'pinia'

export const useCheckoutStoreModal = defineStore('checkoutModalStore', () => {
  const active = ref(false)

  function enable() {
    active.value = true
  }

  function disable() {
    active.value = false
  }

  return { active, enable, disable }
})
