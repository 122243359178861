import { stringify } from 'qs'
import TokenSerializer from '~/serializer/Token'

export default {
  async getBase(
    { clientID, clientSecret }: { clientID: string; clientSecret: string },
  ) {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()
    const { data } = await $axios.post(
      '/api/v2/oauth/token',
      stringify({
        grant_type: 'client_credentials',
        client_id: clientID,
        client_secret: clientSecret,
      }),
      {
        params: {
          isTokenPath: true,
        },
        baseURL: runtimeConfig.public.authUrl,
      },
    )

    return TokenSerializer(data.result)
  },
  async refresh(
    { refreshToken }: { refreshToken: string },
  ) {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()
    const { data } = await $axios.post(
      '/api/v2/oauth/token',
      stringify({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      }),
      {
        params: {
          isTokenPath: true,
        },
        baseURL: runtimeConfig.public.authUrl,
      },
    )
    return TokenSerializer(data.result)
  },
  async reset(
    { refreshToken }: { refreshToken: string },
  ) {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()
    const { data } = await $axios.post(
      '/api/v2/oauth/token',
      stringify({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        reset_authorization: true,
      }),
      {
        params: {
          isTokenPath: true,
        },
        baseURL: runtimeConfig.public.authUrl,
      },
    )

    return TokenSerializer(data.result)
  },
}
