import { useCartStore } from '~/store/cart'
import { useOrderStore } from '~/store/order'
import type { DeliveryType } from '~/type/DeliveryTime'

export function checkDeliveryType() {
  const orderStore = useOrderStore()
  const cartStore = useCartStore()

  const hasValue = computed(() => ['delayed', 'express'].some((field: string) => cartStore.deliveryTimeData[field as DeliveryType]))

  if (!hasValue.value)
    orderStore.setDeliveryType()
  else if (cartStore.deliveryTimeData.delayed && !cartStore.deliveryTimeData.express)
    orderStore.setDeliveryType('delayed')
}
