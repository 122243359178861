import { stringify } from 'qs'
import OrderCheckoutSerializer from '~/serializer/OrderCheckout'
import type { Product } from '~/type/product/Product'
import type { DeliveryType } from '~/type/DeliveryTime'

/**
 * Десериализуем аш формат товаров в формат, который может переварить АПИ 05
 */
function convertProducts(data?: Product[] | null) {
  return data
    ? Object.values(data).map(el => ({
      ID: el.id,
      QUANTITY: el.quantity,
    }))
    : []
}

export default {
  async sync({ data: productList, cart, deliveryType }: { data?: Product[] | null; cart?: Product[] | null; deliveryType?: DeliveryType } = {}) {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.put(
      `/api/v1/darkstore/basket?${stringify({ RESPONSE: 'ORDER', DELIVERY_TYPE: deliveryType })}`,
      {
        PRODUCTS: [...convertProducts(productList), ...convertProducts(cart)],
      },
    )
    return {
      result: OrderCheckoutSerializer(data.result),
    }
  },
  async add({ id, quantity = 1, deliveryType }: { id: string; quantity?: number; deliveryType?: DeliveryType }) {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.put(`/api/v1/darkstore/basket/${id}?${stringify({ QUANTITY: quantity, RESPONSE: 'ORDER', DELIVERY_TYPE: deliveryType })}`)
    return {
      result: OrderCheckoutSerializer(data.result),
    }
  },
  async remove({ id, deliveryType }: { id: string; deliveryType?: DeliveryType }) {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.delete(`/api/v1/darkstore/basket/${id}?${stringify({ RESPONSE: 'ORDER', DELIVERY_TYPE: deliveryType })}`, {
      'axios-retry': {
        retries: 2,
      },
    })
    return {
      result: OrderCheckoutSerializer(data.result),
    }
  },
  async removeAll(deliveryType?: DeliveryType) {
    const { $axios } = useNuxtApp()
    const { data } = (await $axios.delete(`/api/v1/darkstore/basket/all?${stringify({ RESPONSE: 'ORDER', DELIVERY_TYPE: deliveryType })}`, {
      'axios-retry': {
        retries: 2,
      },
    }))
    return {
      result: OrderCheckoutSerializer(data.result),
    }
  },
}
