import type { DeliveryTime } from '~/type/DeliveryTime'

export default function (el: {
  express?: { id: string; pricing: any[] }
  delayed?: { id: string; pricing: any[]; days: any[] }
}): DeliveryTime {
  function convertValuesToNumber(arr: any[]) {
    return arr.map((obj) => {
      for (const key in obj)
        obj[key] = Number(obj[key])
      return obj
    })
  }

  return {
    express: el?.express
      ? {
          id: el.express.id,
          pricing: el.express?.pricing
            ? convertValuesToNumber(el.express.pricing)
            : [],
        }
      : undefined,
    delayed: el?.delayed
      ? {
          id: el.delayed.id,
          pricing: el.delayed?.pricing
            ? convertValuesToNumber(el.delayed.pricing)
            : [],
          days: el.delayed.days.map((value: { date: string; slots: any[] }) => ({
            date: {
              day: value.date.split('-')[2],
              month: value.date.split('-')[1],
              year: value.date.split('-')[0],
            },
            time: value.slots,
          })),
        }
      : undefined,
  }
}
