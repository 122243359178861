<script setup lang="ts">
const isNoteShow = ref(true)
</script>

<template>
  <Transition name="fade">
    <div
      v-if="isNoteShow"
      class="map-note"
      @click="isNoteShow = false"
    >
      <span class="mt-5">💬</span>
      <p class="map-note__text">
        Ассортимент в каждом Близко может
        отличаться в зависимости
        от вашего адреса
      </p>
      <img
        src="./asset/note-close.svg"
        alt="Закрыть уведомление"
        class="map-note__icon"
      >
      <img
        src="./asset/vector.svg"
        alt="Закрыть уведомление"
        class="map-note__point"
      >
    </div>
  </Transition>
</template>

<style scoped lang="postcss">
.map-note {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 12px 15px;
  margin-bottom: 14px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 8px 40px 0px rgba(50, 50, 71, 0.06), 0px 8px 8px 0px rgba(50, 50, 71, 0.02);

  &__point {
    position: absolute;
    bottom: -7px;
    left: 20px;
  }

  &__text {
    max-width: 182px;
    line-height: 20px;
  }

  &__icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  @media (max-width: 800px) {

    &__text {
      max-width: 75%;
    }
  }
}
</style>
