<script setup lang="ts">
import { storeToRefs } from 'pinia'
import getMonthName from '~/helper/getMonthName'
import { useCartStore } from '~/store/cart'
import { useCheckoutStoreModal } from '~/store/modal/checkout'
import { useDeliveryTimeSelectModalStore } from '~/store/modal/deliveryTimeSelect'
import { useOrderStore } from '~/store/order'
import { useResizeStore } from '~/store/resize'
import type { DeliveryTimeSlot } from '~/type/DeliveryTime'

const deliveryTimeSelectModalStore = useDeliveryTimeSelectModalStore()
const checkoutModalStore = useCheckoutStoreModal()
const orderStore = useOrderStore()
const cartStore = useCartStore()
const { isMobile } = storeToRefs(useResizeStore())
const dayIndex = ref(0)
const time = ref<DeliveryTimeSlot | undefined>(orderStore.deliveryDate?.time)

const delayedDayType = computed(() => {
  if (cartStore.deliveryTimeData.delayed)
    return Number(cartStore.deliveryTimeData.delayed.days[dayIndex.value].date.day) === new Date().getDate() ? 'Сегодня' : 'Завтра'
})
const delayedDateValue = computed(() => {
  if (cartStore.deliveryTimeData.delayed)
    return `${cartStore.deliveryTimeData.delayed.days[dayIndex.value].date.day} ${getMonthName(cartStore.deliveryTimeData.delayed.days[dayIndex.value].date)}`
})

onUnmounted(() => onClose())

function changeDayIndex(type: 'decrease' | 'increase') {
  if (type === 'decrease') {
    dayIndex.value = dayIndex.value > 0 ? dayIndex.value -= 1 : 0
  }
  else {
    if (cartStore.deliveryTimeData.delayed?.days) {
      const deliveryDaysLength = cartStore.deliveryTimeData.delayed.days.length - 1
      dayIndex.value = dayIndex.value < deliveryDaysLength ? dayIndex.value += 1 : deliveryDaysLength
    }
  }
  time.value = undefined
}
function onClose() {
  orderStore.setExpiredDateValue(false)
  if (time.value && cartStore.deliveryTimeData.delayed) {
    orderStore.setDeliveryDate({
      date: {
        day: cartStore.deliveryTimeData.delayed.days[dayIndex.value].date.day,
        month: cartStore.deliveryTimeData.delayed.days[dayIndex.value].date.month,
        year: cartStore.deliveryTimeData.delayed.days[dayIndex.value].date.year,
      },
      time: time.value,
    })
  }
  else {
    orderStore.setDeliveryDate(undefined)
  }
  deliveryTimeSelectModalStore.disable()
  if (isMobile.value)
    checkoutModalStore.enable()
}

function onSlotClick(slot: DeliveryTimeSlot) {
  if (slot.isAvailable) {
    if (slot.startTime !== time.value?.startTime && slot.endTime !== time.value?.endTime)
      time.value = slot
  }
}
</script>

<template>
  <CCDeliveryTimeModal
    :show="deliveryTimeSelectModalStore.active"
    title="Выберите слот"
    @close="onClose"
  >
    <template #default>
      <div class="delivery-time-select-modal">
        <div v-if="cartStore.deliveryTimeData.delayed?.days" class="delivery-time-select-modal__header">
          <img
            src="./asset/left-arrow.svg"
            alt="Влево"
            class="delivery-time-select-modal__arrow"
            :class="{ 'delivery-time-select-modal__arrow_disabled': dayIndex === 0 }"
            @click="changeDayIndex('decrease')"
          >
          <div class="delivery-time-select-modal__info">
            <p class="delivery-time-select-modal__title">
              {{ delayedDayType }}
            </p>
            <p class="delivery-time-select-modal__description">
              {{ delayedDateValue }}
            </p>
          </div>
          <img
            src="./asset/right-arrow.svg"
            alt="Вправо"
            class="delivery-time-select-modal__arrow"
            :class="{ 'delivery-time-select-modal__arrow_disabled': dayIndex === cartStore.deliveryTimeData.delayed.days.length - 1 }"
            @click="changeDayIndex('increase')"
          >
        </div>
        <div class="delivery-time-select-modal__content">
          <div
            v-for="(slot, slotIndex) in cartStore.deliveryTimeData.delayed?.days[dayIndex].time"
            :key="slotIndex"
            class="delivery-time-select-modal__time-item"
            :class="{
              'delivery-time-select-modal__time-item_active': time && slot.startTime === time.startTime && slot.endTime === time.endTime,
              'delivery-time-select-modal__time-item_disabled': !slot.isAvailable,
              'delivery-time-select-modal__time-item_none-selected': time && slot.startTime !== time.startTime && slot.endTime !== time.endTime,
            }"
            @click="onSlotClick(slot)"
          >
            <img
              v-if="slot.isAvailable"
              src="./asset/delivery-price-blue.svg"
              alt="Выбрать время"
              class="delivery-time-select-modal__icon delivery-time-select-modal__icon_delivery"
            >
            <img
              v-else
              src="./asset/unavailable.svg"
              alt="Недоступно"
              class="delivery-time-select-modal__icon delivery-time-select-modal__icon_unavailable"
            >
            <p class="delivery-time-select-modal__time">
              {{ slot.startTime }} - {{ slot.endTime }}
            </p>
          </div>
        </div>
        <div class="delivery-time-select-modal__button-wrap">
          <button
            class="delivery-time-select-modal__button"
            :class="{ 'delivery-time-select-modal__button_disabled': !time }"
            @click="onClose"
          >
            Продолжить оформление
          </button>
        </div>
      </div>
    </template>
  </CCDeliveryTimeModal>
</template>

<style scoped lang="postcss">
.delivery-time-select-modal {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  &__time-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 8px 28px;
    cursor: pointer;
    border: 1px solid #F1F1F1;
    border-radius: 12px;

    &_active {
      background-color: #F6F9FE;
      border: 1px solid #D7E6FF;
    }

    &_disabled {
      opacity: 0.4;
    }

    &_none-selected {

      .delivery-time-select-modal__icon_delivery {
        opacity: 0.4;
      }
    }
  }

  &__title {
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }

  &__description {
    font-size: 14px;
    line-height: 16px;
    color: #818181;
  }

  &__info {
    margin: 0 16px;
    text-align: center;
  }

  &__time {
    line-height: 21px;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    margin-bottom: 15px;
    border-top: 1px solid #F3F3F3;
  }

  &__arrow {
    width: 6px;
    cursor: pointer;

    &_disabled {
      cursor: default;
      opacity: 0.2;
    }
  }

  &__button {
    width: 100%;
    padding: 17px 14px;
    color: #fff;
    cursor: pointer;
    background-color: #00B0B8;
    border: none;
    border-radius: 12px;

    &_disabled {
      opacity: 0.4;
    }
  }

  &__button-wrap {
    width: 100vw;
    padding: 10px 10px 20px;
    margin: 15px -20px -20px;
    border-radius: 12px;
    box-shadow: 0px -2px 10px 0px #00000014;
  }

  &__icon {
    width: 20px;
    height: 20px;
    margin-bottom: 2px;

    &_unavailable {
      width: 15px;
      height: 15px;
      margin-bottom: 7px;
    }
  }

  @media (min-width: 1000px) {
    margin: initial;

    &__content {
      grid-template-columns: repeat(3, 1fr);
    }

    &__header {
      margin-bottom: 20px;
      border: none;
    }

    &__button-wrap {
      width: initial;
      box-shadow: none;
    }
  }
}
</style>
