import { defineStore } from 'pinia'
import type { PaymentCard } from '~/type/PaymentCard'

export const useSelectPaymentModalStore = defineStore('selectPaymentModalStore', () => {
  const active = ref(false)
  const paymentCard = ref<PaymentCard | null>()

  function enable() {
    active.value = true
  }

  function disable() {
    active.value = false
  }

  function setPaymentCard(card?: PaymentCard) {
    paymentCard.value = card ?? null
  }

  return { active, paymentCard, enable, disable, setPaymentCard }
})
