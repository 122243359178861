import { formatAddress } from '@artmizu/utils'
import { ru } from 'date-fns/locale'
import { parse, parseISO } from 'date-fns'
import { storeToRefs } from 'pinia'
import OrderProduct from './OrderProduct'
import formatDate from '~/helper/formatDate'
import type { Order, StatusType } from '~/type/order/Order'
import { useServiceStore } from '~/store/service'

export default function (el: {
  [key: string]: any
  PRODUCTS: any[]
}): Order {
  function getStatusType(el: any): StatusType {
    switch (el.STATUS_ID.ID) {
      case 'M':
      case 'PP':
        return 'not-paid'
      case 'CU':
      case 'Q':
      case 'RJ':
        return 'cancelled'
      case 'W':
        return 'accepted'
      case 'B':
      case 'CM':
      case 'RS':
        return 'collect'
      case 'DD':
      case 'G':
        return 'transit'
      case 'F':
      case 'E':
        return 'completed'
      default:
        return 'not-paid-yet'
    }
  }

  const { currentDate } = storeToRefs(useServiceStore())

  // Получаем минуты из строки даты доставки
  function getDeliveryMinutes(time: string) {
    const newDate = currentDate.value ? new Date(currentDate.value) : new Date()
    const date = parse(time, 'dd.MM.yyyy HH:mm:ss', newDate, { locale: ru })
    return Math.max(Math.ceil(((+date - +newDate) / 1000) / 60), 0)
  }

  function getPayExpireSeconds(date: string) {
    const targetDate = parseISO(date)
    return Math.max(Math.ceil((+targetDate - +new Date()) / 1000), 0)
  }

  return {
    id: el.ID,
    date: formatDate(el.DATE_INSERT, 'd MMMM'),
    number: el.ACCOUNT_NUMBER,
    price: {
      common: {
        value: el.PRICE,
      },
    },
    status: {
      name: el.STATUS_ID?.NAME,
      description: el?.STATUS_DESCRIPTION,
      color: el.STATUS_ID?.COLOR,
      /*
        если пришла дата, значит это отложенный тип доставки
      */
      type: (el.DELIVERY_DATE && getStatusType(el) !== 'not-paid' && getStatusType(el) !== 'not-paid-yet' && getStatusType(el) === 'accepted') ? 'delayed' : getStatusType(el),
    },
    delivery: {
      name: el.DELIVERY_NAME,
      date: el.DELIVERY_DATE,
      time: el.DARKSTORE_DELIVERY_ESTIMATE ? getDeliveryMinutes(el.DARKSTORE_DELIVERY_ESTIMATE) : 0,
      detail: el?.DECODED_ADDRESS
        ? {
            address: formatAddress({ city: el?.DECODED_ADDRESS.CITY, street: el?.DECODED_ADDRESS.STREET, house: el?.DECODED_ADDRESS.HOUSE }),
            city: el?.DECODED_ADDRESS.CITY,
          }
        : undefined,
    },
    payResult: el.PAY_RESULT,
    isEvaluated: el.ORDER_EVALUATED,
    checkLink: el?.DARKSTORE_PAY_CHECK_LINK,
    isDelay: el.DARKSTORE_DELIVERY_DELAY === 'DELAY',
    courierPhone: el?.DARKSTORE_COURIER_PHONE,
    supportLink: el.DARKSTORE_SUPPORT_LINK,
    pay: el.PAY_SYSTEM_NAME,
    leaveAtTheDoor: el?.DARKSTORE_LEAVE_AT_THE_DOOR,
    link: el.PAY_SYSTEM_LINK,
    products: el.PRODUCTS?.map((el: any) => OrderProduct(el)) || [],
    isChanged: el.DARKSTORE_HAS_CHANGES,
    changedPrice: el.PREVIOUS_PRICE || 0,
    paymentCardID: el.PAY_CARD_ID,
    payLinkExpireTime: el.PAY_SYSTEM_LINK_EXPIRE_DATE && getPayExpireSeconds(el.PAY_SYSTEM_LINK_EXPIRE_DATE),
  }
}
