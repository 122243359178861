import type { DeliveryDate } from '~/type/DeliveryTime'

/**
   * @example
   * Вернет 'января'
   * getMonthName({ year: '2022', month: '01', day: '01' })
   */
export default function getMonthName(date: DeliveryDate) {
  const dateString = `${date.year}-${date.month}-${date.day}`
  const monthsNames = [
    'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
    'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря',
  ]

  return monthsNames[new Date(dateString).getMonth()]
}
