import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { DeliveryOrderDate, DeliveryType } from '~/type/DeliveryTime'

export const useOrderStore = defineStore('order', () => {
  const deliveryDate = ref<DeliveryOrderDate>()
  const deliveryType = ref<DeliveryType | undefined>('express')
  const timeExpired = ref(false)
  function setDeliveryDate(date?: DeliveryOrderDate) {
    deliveryDate.value = date ?? undefined
  }

  function setDeliveryType(type?: DeliveryType) {
    deliveryType.value = type ?? undefined
  }

  function setExpiredDateValue(state: boolean) {
    timeExpired.value = state
  }

  return { deliveryDate, deliveryType, timeExpired, setDeliveryDate, setDeliveryType, setExpiredDateValue }
}, {
  persist: true,
  share: {
    enable: true,
  },
})
