<script setup lang="ts">
import { useServiceModal } from '~/store/modal/service'
import type { ServiceType } from '~/type/Service'

defineProps<{
  type: ServiceType
}>()

const serviceModalStore = useServiceModal()

const modalData: { [key in ServiceType]: { [key: string]: string } } = {
  'unavailable': {
    title: 'Сервис недоступен',
    description: 'Из-за технических неполадок, временно не принимаем заказы. Скоро мы все исправим',
    buttonText: 'Просто посмотрю что у вас есть',
  },
  'off-hours': {
    title: 'Закажите на завтра',
    description: 'Пока мы не можем работать круглосуточно, но обязательно этому научимся. Вы можете сейчас оформить заказ, завтра доставим!',
    buttonText: 'На главную',
  },
  'no-delivery': {
    title: 'Сейчас закрыты',
    description: 'Пока мы не можем работать круглосуточно, но обязательно этому научимся. Вы сможете заказать продукты с ',
    timeText: '9:00 - 21:00',
    buttonText: 'На главную',
  },
}

function onOverlayClick(payload: Event) {
  const isOverlay = (payload.target as HTMLElement).classList.contains('service-modal__overlay')
  if (isOverlay)
    serviceModalStore.disable()
}
</script>

<template>
  <transition
    :duration="{ enter: 400, leave: 400 }"
    enter-from-class="fade-in-up-enter"
    enter-active-class="fade-in-up-enter-active"
    enter-to-class="fade-in-up-enter-to"
    leave-from-class="fade-out-down-leave"
    leave-active-class="fade-out-down-leave-active"
    leave-to-class="fade-out-down-leave-to"
  >
    <div
      v-if="serviceModalStore.active"
      class="service-modal__overlay"
      @click="onOverlayClick"
    >
      <div :class="`service-modal__modal service-modal__modal_${type}`">
        <div class="service-modal__modal-close" @click="serviceModalStore.disable" />
        <div class="service-modal__title">
          {{ modalData[type].title }}
        </div>
        <p class="service-modal__text">
          {{ modalData[type].description }} <span
            v-if="type === 'no-delivery'"
            class="service-modal__time-text"
          >{{ modalData[type].timeText }}</span>
        </p>
        <div class="service-modal__button__wrapper">
          <button class="service-modal__button" @click="serviceModalStore.disable">
            {{ modalData[type].buttonText }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="postcss" scoped>
.service-modal {

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--z-above-modal);
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* Оставляем 100vh, на случай, если не сработает -webkit-fill-available */
    min-height: 100vh;
    min-height: -webkit-fill-available;
    padding-right: 20px;
    margin-right: -20px;
    overflow-y: scroll;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 1;
    will-change: transform, opacity;
  }

  &__modal {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    max-width: 425px;
    padding: 20px;
    margin: auto;
    overflow-y: scroll;
    color: #fff;
    background-color: #F8F8F8;
    border-radius: 20px;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__title {
    margin-bottom: 15px;
    font-size: 29px;
    font-weight: 500;
    line-height: 33px;
    color: #000;
  }

  &__text {
    max-width: 350px;
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 20px;
    color: #A2A2A2;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 21px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #fff;
    cursor: pointer;
    background-color: #00B0B8;
    border: none;
    border-radius: 20px;
    transition: opacity 0.2s ease-out;

    &:hover {
      opacity: 0.7;
    }
  }

  &__modal-close {
    position: absolute;
    top: 25px;
    right: 25px;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-image: svg-load('./asset/close-icon.svg', fill=#000);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 24px;
    transition: opacity 0.2s ease-out;

    &:hover {
      opacity: 0.7;
    }
  }

  &__time-text {
    color: #00B0B8;
  }

  @media (max-width: 900px) {

    &__overlay {
      top: inherit;
    }

    &__modal {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      min-height: 100%;
      padding: 13px 15px 15px;
      margin-bottom: 0;
      border-right: 0;
      border-bottom: 0;
      border-left: 0;
      border-radius: 20px 20px 0px 0px;
    }

    &__modal-close {
      display: none;
    }

    &__title {
      position: relative;
      padding: 15px 0 10px;
      margin: 0;
      font-size: 22px;
      line-height: 25px;
      color: #00B0B8;

      &::before {
        position: absolute;
        top: -5px;
        left: 50%;
        width: 14%;
        height: 5px;
        content: '';
        background-color: #E5E5E5;
        border-radius: 20px;
        transform: translateX(-50%);
      }
    }

    &__text {
      max-width: 100%;
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 18px;
    }

    &__button__wrapper {
      padding-bottom: 30px;

      &::after {
        position: absolute;
        bottom: 8px;
        left: 50%;
        width: 42%;
        height: 5px;
        content: '';
        background-color: #5C5C5C;
        border-radius: 20px;
        transform: translateX(-50%);
      }
    }

    &__button {
      padding: 20px;
      font-size: 16px;
      line-height: 22px;
    }

    &__br {
      display: block;
    }
  }

  @media (max-width: 800px) {

    &__modal {
      margin: initial;
      /* Стиль для того чтобы прибивать модалки к низу на мобильном разрешении */
      margin-top: auto;
    }
  }
}

/* Кастомная анимация появления */
@keyframes customFadeInUp {

  from {
    transform: translateY(70px);
  }

  to {
    transform: translateY(0);
  }
}

/* Кастомная анимация исчезновения */
@keyframes customFadeOutDown {

  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(70px);
  }
}

.fade-out-down-leave-active,
.fade-in-up-enter-active {
  transition: opacity 0.4s;

  .service-modal {
    animation: 0.3s customFadeInUp;
  }
}

.fade-in-up-enter,
.fade-out-down-leave-active {
  transition: opacity 0.5s;

  .service-modal {
    animation: 0.5s customFadeOutDown;
  }
}
</style>
