import { defineStore } from 'pinia'
import { useCartStore } from '../cart'

export const useServiceModal = defineStore('serviceModal', () => {
  const cartStore = useCartStore()

  const active = ref(false)

  function enable() {
    active.value = true
  }

  function disable() {
    active.value = false

    if (cartStore.serviceType === 'no-delivery' || cartStore.serviceType === 'unavailable')
      void navigateTo('/')
  }

  return { active, enable, disable }
})
